export const demoForm = {
  id: '3e3ea306-3cfb-4bcb-b1e3-a591c563e873',
  fields: [
    {
      id: '6004517a-fece-4087-0b10-ba4548ddd182',
      pii: false,
      hidden: false,
      view: {
        submitText: 'Submit',
        thankYouMessageDuration: 8,
        thankYouMessageText: {
          nodes: [
            {
              type: 'PARAGRAPH',
              id: 'edujz5',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Thanks, we received your submission.',
                    decorations: [],
                  },
                },
              ],
              paragraphData: {
                textStyle: {
                  textAlignment: 'CENTER',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T08:41:10.437Z',
            updatedTimestamp: '2024-08-29T08:41:10.437Z',
            id: '15f70f3c-dcb8-4699-9480-b11ac41217c6',
          },
        },
        nextText: 'Next',
        submitAction: 'THANK_YOU_MESSAGE',
        fieldType: 'SUBMIT_BUTTON',
        previousText: 'Back',
      },
    },
    {
      id: 'd2f37505-9592-4f5b-3599-7275075e7054',
      pii: false,
      hidden: false,
      view: {
        submitText: 'Submit',
        thankYouMessageDuration: 8,
        thankYouMessageText: {
          nodes: [
            {
              type: 'PARAGRAPH',
              id: '7qk8d113',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Thanks, we received your submission.',
                    decorations: [],
                  },
                },
              ],
              paragraphData: {
                textStyle: {
                  textAlignment: 'CENTER',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T08:44:11.172Z',
            updatedTimestamp: '2024-08-29T08:44:11.172Z',
            id: '71152471-db25-44d5-ad48-b26d57b60f64',
          },
        },
        nextText: 'Next',
        submitAction: 'THANK_YOU_MESSAGE',
        fieldType: 'SUBMIT_BUTTON',
        previousText: 'Back',
      },
    },
    {
      id: 'd14ec67e-dab0-4a89-770a-405934c50534',
      pii: false,
      hidden: false,
      view: {
        content: {
          nodes: [
            {
              type: 'HEADING',
              id: '230dc73',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Header 1 text',
                    decorations: [],
                  },
                },
              ],
              headingData: {
                level: 1,
                textStyle: {
                  textAlignment: 'AUTO',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T09:32:09.799Z',
            updatedTimestamp: '2024-08-29T09:32:09.799Z',
            id: '6765cfe5-a22c-4b1f-a029-306255bd2658',
          },
          documentStyle: {},
        },
        fieldType: 'HEADER',
      },
    },
    {
      id: '6fa99389-a5e1-465f-e235-c3589e657126',
      pii: false,
      hidden: false,
      view: {
        content: {
          nodes: [
            {
              type: 'PARAGRAPH',
              id: '4m1ps76',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Paragraph text',
                    decorations: [],
                  },
                },
              ],
              paragraphData: {
                textStyle: {
                  textAlignment: 'AUTO',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T09:32:09.801Z',
            updatedTimestamp: '2024-08-29T09:32:09.801Z',
            id: '762f36c1-53c0-4590-9768-1d10e7ce677a',
          },
          documentStyle: {},
        },
        fieldType: 'RICH_TEXT',
      },
    },
    {
      id: 'f6a70a2b-c6b4-4bd9-6980-7b872b426457',
      pii: false,
      hidden: false,
      view: {
        content: {
          nodes: [
            {
              type: 'HEADING',
              id: '230dc73',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Heading 2 text',
                    decorations: [],
                  },
                },
              ],
              headingData: {
                level: 2,
                textStyle: {
                  textAlignment: 'AUTO',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T09:32:09.799Z',
            updatedTimestamp: '2024-08-29T09:32:09.799Z',
            id: '6765cfe5-a22c-4b1f-a029-306255bd2658',
          },
          documentStyle: {},
        },
        fieldType: 'HEADER',
      },
    },
    {
      id: '0acb9567-6b51-446b-0466-8e9b155775f6',
      target: 'short_answer_613a',
      validation: {
        string: {
          format: 'UNDEFINED',
          enum: [],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Short answer',
        fieldType: 'TEXT_INPUT',
        hideLabel: false,
      },
    },
    {
      id: 'a869b366-732b-487f-0a05-32d022a099e0',
      target: 'short_answer_613a_1',
      validation: {
        string: {
          format: 'UNDEFINED',
          enum: [],
        },
        required: true,
      },
      pii: false,
      hidden: false,
      view: {
        description: {
          nodes: [
            {
              type: 'PARAGRAPH',
              id: 'foo',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'This is field description',
                    decorations: [],
                  },
                },
              ],
              paragraphData: {
                textStyle: {
                  textAlignment: 'AUTO',
                },
                indentation: 0,
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2022-12-19T17:56:18.279Z',
            updatedTimestamp: '2022-12-19T17:56:18.279Z',
            id: '1d4b7578-58b9-4c3f-bf6d-6f5b6429fbb6',
          },
          documentStyle: {},
        },
        label: 'Short answer',
        placeholder: 'Answer in a few words',
        hideLabel: false,
        fieldType: 'TEXT_INPUT',
      },
    },
    {
      id: '008f6554-5801-4e17-d97a-1bd57dda89f0',
      target: 'star_rating_a04b',
      validation: {
        number: {
          maximum: 5,
          minimum: 1,
          multipleOf: 1,
          enum: [],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Star rating',
        fieldType: 'RATING_INPUT',
        hideLabel: false,
      },
    },
    {
      id: 'b6451976-0ff0-40eb-07da-9adfd3ff6e1c',
      target: 'multi_choice_1fed',
      validation: {
        array: {
          items: {
            string: {
              format: 'UNDEFINED',
              enum: ['Option 1', 'Option 2', 'Option 3'],
            },
          },
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Multi choice',
        options: [
          {
            label: 'Option 1',
            value: 'Option 1',
            id: 'c332a3df-90c2-427d-cadc-5e0a9d04ff60',
          },
          {
            label: 'Option 2',
            value: 'Option 2',
            id: '56e25421-57c6-44d1-484c-8f244f94c32f',
          },
          {
            label: 'Option 3',
            value: 'Option 3',
            id: '76181e31-b2ab-4202-b118-6bff764f8faa',
          },
        ],
        addOther: false,
        hideLabel: false,
        numberOfColumns: 3,
        fieldType: 'CHECKBOX_GROUP',
        defaultValue: ['Option 1', 'Option 3'],
      },
    },
    {
      id: '311d6405-09ce-45aa-d897-72d3649a079b',
      target: 'form_field_c925',
      validation: {
        boolean: {
          enum: [],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: {
          nodes: [
            {
              type: 'PARAGRAPH',
              id: 'h88m557',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'This is a checkbox.',
                    decorations: [],
                  },
                },
              ],
              paragraphData: {
                textStyle: {
                  textAlignment: 'AUTO',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T09:38:55.549Z',
            updatedTimestamp: '2024-08-29T09:38:55.549Z',
            id: '2c3e3ada-11a2-49df-a86d-9ad743a5a09e',
          },
        },
        fieldType: 'CHECKBOX',
      },
    },
    {
      id: '42b0dd10-599d-43e1-f4ec-2f9f1cdbacf0',
      target: 'date_picker_77eb',
      validation: {
        string: {
          format: 'DATE',
          enum: [],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        hidePlaceholder: false,
        label: 'Date picker',
        hideLabel: false,
        firstDayOfWeek: 'MONDAY',
        fieldType: 'DATE_PICKER',
      },
    },
    {
      id: '2933c8f2-7725-469f-6304-6dd37f7b20e0',
      target: 'dropdown_4c4a',
      validation: {
        string: {
          format: 'UNDEFINED',
          enum: ['Option 1', 'Option 2'],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Dropdown',
        options: [
          {
            label: 'Option 1',
            value: 'Option 1',
            id: 'afa3de59-59c3-4aa1-e58c-e0f7b2cdbe77',
          },
          {
            label: 'Option 2',
            value: 'Option 2',
            id: '345db19c-be28-40b2-631a-060a837bc848',
          },
        ],
        addOther: false,
        hideLabel: false,
        fieldType: 'DROPDOWN',
      },
    },
    {
      id: 'b9de78fd-35be-4877-66ca-8a1b1d39216b',
      pii: false,
      hidden: false,
      view: {
        content: {
          nodes: [
            {
              type: 'HEADING',
              id: '4pdl373',
              nodes: [
                {
                  type: 'TEXT',
                  id: '',
                  nodes: [],
                  textData: {
                    text: 'Second page',
                    decorations: [],
                  },
                },
              ],
              headingData: {
                level: 1,
                textStyle: {
                  textAlignment: 'AUTO',
                },
              },
            },
          ],
          metadata: {
            version: 1,
            createdTimestamp: '2024-08-29T09:38:55.559Z',
            updatedTimestamp: '2024-08-29T09:38:55.559Z',
            id: '83ace24c-11b5-40da-a14b-cf6ec4fab6d7',
          },
          documentStyle: {},
        },
        fieldType: 'HEADER',
      },
    },
    {
      id: 'aa337a06-b503-4be1-ad78-d51d200d2460',
      target: 'file_upload_c165',
      validation: {
        predefined: {
          format: 'WIX_FILE',
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'File upload',
        uploadFileFormats: ['Video', 'Image'],
        placeholder: 'File upload',
        buttonText: 'Upload File',
        valueSliderLimit: 1,
        hideLabel: false,
        fieldType: 'FILE_UPLOAD',
      },
    },
    {
      id: '2c2cd5c4-2141-4298-444b-0814e4935609',
      target: 'single_choice_8edc',
      validation: {
        string: {
          format: 'UNDEFINED',
          enum: ['Option 1', 'Option 2', 'Option 3'],
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Single choice',
        options: [
          {
            label: 'Option 1',
            value: 'Option 1',
            id: '1c349276-3193-4e2d-6a56-716ffb7179c3',
          },
          {
            label: 'Option 2',
            value: 'Option 2',
            id: '4fa1b204-42a2-4bb7-2d4e-5cf46b7220af',
          },
          {
            label: 'Option 3',
            value: 'Option 3',
            id: '2e5de5c6-0b45-436e-05d0-8103e0fc7812',
          },
        ],
        addOther: false,
        hideLabel: false,
        numberOfColumns: 3,
        fieldType: 'RADIO_GROUP',
        defaultValue: 'Option 1',
      },
    },
    {
      id: 'e5be0180-9138-43aa-e5ee-1b2c56b5fd0c',
      target: 'donation_25b1',
      validation: {
        predefined: {
          format: 'PAYMENT',
          paymentOptions: {
            products: [
              {
                id: '731f16d1-a31f-4571-dc7b-f6c8065644d3',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '10',
                },
              },
              {
                id: '2a175c75-2553-45ca-3f5c-b34c9110bceb',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '20',
                },
              },
              {
                id: 'd208ee0c-d764-4b54-1326-aa62c6546f16',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '30',
                },
              },
            ],
          },
        },
        required: false,
      },
      pii: false,
      hidden: false,
      view: {
        label: 'Donation',
        options: [
          {
            value: '731f16d1-a31f-4571-dc7b-f6c8065644d3',
          },
          {
            value: '2a175c75-2553-45ca-3f5c-b34c9110bceb',
          },
          {
            value: 'd208ee0c-d764-4b54-1326-aa62c6546f16',
          },
        ],
        addOther: false,
        hideLabel: false,
        numberOfColumns: 3,
        fieldType: 'DONATION',
      },
    },
  ],
  fieldsV2: [
    {
      id: '6004517a-fece-4087-0b10-ba4548ddd182',
      hidden: false,
      identifier: 'SUBMIT_BUTTON',
      fieldType: 'SUBMIT',
      submitOptions: {
        nextText: 'Next',
        previousText: 'Back',
        submitText: 'Submit',
        thankYouMessage: {
          text: {
            nodes: [
              {
                type: 'PARAGRAPH',
                id: 'edujz5',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Thanks, we received your submission.',
                      decorations: [],
                    },
                  },
                ],
                paragraphData: {
                  textStyle: {
                    textAlignment: 'CENTER',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T08:41:10.437Z',
              updatedTimestamp: '2024-08-29T08:41:10.437Z',
              id: '15f70f3c-dcb8-4699-9480-b11ac41217c6',
            },
          },
          duration: 8,
        },
      },
    },
    {
      id: 'd2f37505-9592-4f5b-3599-7275075e7054',
      hidden: false,
      identifier: 'SUBMIT_BUTTON',
      fieldType: 'SUBMIT',
      submitOptions: {
        nextText: 'Next',
        previousText: 'Back',
        submitText: 'Submit',
        thankYouMessage: {
          text: {
            nodes: [
              {
                type: 'PARAGRAPH',
                id: '7qk8d113',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Thanks, we received your submission.',
                      decorations: [],
                    },
                  },
                ],
                paragraphData: {
                  textStyle: {
                    textAlignment: 'CENTER',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T08:44:11.172Z',
              updatedTimestamp: '2024-08-29T08:44:11.172Z',
              id: '71152471-db25-44d5-ad48-b26d57b60f64',
            },
          },
          duration: 8,
        },
      },
    },
    {
      id: 'd14ec67e-dab0-4a89-770a-405934c50534',
      hidden: false,
      identifier: 'HEADER',
      fieldType: 'DISPLAY',
      displayOptions: {
        header: {
          content: {
            nodes: [
              {
                type: 'HEADING',
                id: '230dc73',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Header 1 text',
                      decorations: [],
                    },
                  },
                ],
                headingData: {
                  level: 1,
                  textStyle: {
                    textAlignment: 'AUTO',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T09:32:09.799Z',
              updatedTimestamp: '2024-08-29T09:32:09.799Z',
              id: '6765cfe5-a22c-4b1f-a029-306255bd2658',
            },
            documentStyle: {},
          },
        },
      },
    },
    {
      id: '6fa99389-a5e1-465f-e235-c3589e657126',
      hidden: false,
      identifier: 'RICH_TEXT',
      fieldType: 'DISPLAY',
      displayOptions: {
        richText: {
          content: {
            nodes: [
              {
                type: 'PARAGRAPH',
                id: '4m1ps76',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Paragraph text',
                      decorations: [],
                    },
                  },
                ],
                paragraphData: {
                  textStyle: {
                    textAlignment: 'AUTO',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T09:32:09.801Z',
              updatedTimestamp: '2024-08-29T09:32:09.801Z',
              id: '762f36c1-53c0-4590-9768-1d10e7ce677a',
            },
            documentStyle: {},
          },
        },
      },
    },
    {
      id: 'f6a70a2b-c6b4-4bd9-6980-7b872b426457',
      hidden: false,
      identifier: 'HEADER',
      fieldType: 'DISPLAY',
      displayOptions: {
        header: {
          content: {
            nodes: [
              {
                type: 'HEADING',
                id: '230dc73',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Heading 2 text',
                      decorations: [],
                    },
                  },
                ],
                headingData: {
                  level: 2,
                  textStyle: {
                    textAlignment: 'AUTO',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T09:32:09.799Z',
              updatedTimestamp: '2024-08-29T09:32:09.799Z',
              id: '6765cfe5-a22c-4b1f-a029-306255bd2658',
            },
            documentStyle: {},
          },
        },
      },
    },
    {
      id: '0acb9567-6b51-446b-0466-8e9b155775f6',
      hidden: false,
      identifier: 'TEXT_INPUT',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'short_answer_613a',
        pii: false,
        required: false,
        inputType: 'STRING',
        stringOptions: {
          validation: {
            format: 'UNDEFINED',
            enum: [],
          },
          componentType: 'TEXT_INPUT',
          textInputOptions: {
            label: 'Short answer',
            showLabel: true,
          },
        },
      },
    },
    {
      id: 'a869b366-732b-487f-0a05-32d022a099e0',
      hidden: false,
      identifier: 'TEXT_INPUT',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'short_answer_613a_1',
        pii: false,
        required: true,
        inputType: 'STRING',
        stringOptions: {
          validation: {
            format: 'UNDEFINED',
            enum: [],
          },
          componentType: 'TEXT_INPUT',
          textInputOptions: {
            label: 'Short answer',
            description: {
              nodes: [
                {
                  type: 'PARAGRAPH',
                  id: 'foo',
                  nodes: [
                    {
                      type: 'TEXT',
                      id: '',
                      nodes: [],
                      textData: {
                        text: 'This is field description',
                        decorations: [],
                      },
                    },
                  ],
                  paragraphData: {
                    textStyle: {
                      textAlignment: 'AUTO',
                    },
                    indentation: 0,
                  },
                },
              ],
              metadata: {
                version: 1,
                createdTimestamp: '2022-12-19T17:56:18.279Z',
                updatedTimestamp: '2022-12-19T17:56:18.279Z',
                id: '1d4b7578-58b9-4c3f-bf6d-6f5b6429fbb6',
              },
              documentStyle: {},
            },
            placeholder: 'Answer in a few words',
            showLabel: true,
          },
        },
      },
    },
    {
      id: '008f6554-5801-4e17-d97a-1bd57dda89f0',
      hidden: false,
      identifier: 'RATING_INPUT',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'star_rating_a04b',
        pii: false,
        required: false,
        inputType: 'NUMBER',
        numberOptions: {
          validation: {
            maximum: 5,
            minimum: 1,
            multipleOf: 1,
            enum: [],
          },
          componentType: 'RATING_INPUT',
          ratingInputOptions: {
            label: 'Star rating',
            showLabel: true,
          },
        },
      },
    },
    {
      id: 'b6451976-0ff0-40eb-07da-9adfd3ff6e1c',
      hidden: false,
      identifier: 'CHECKBOX_GROUP',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'multi_choice_1fed',
        pii: false,
        required: false,
        inputType: 'ARRAY',
        arrayOptions: {
          validation: {
            items: {
              itemType: 'STRING',
              stringOptions: {
                format: 'UNDEFINED',
                enum: ['Option 1', 'Option 2', 'Option 3'],
              },
            },
          },
          componentType: 'CHECKBOX_GROUP',
          checkboxGroupOptions: {
            label: 'Multi choice',
            options: [
              {
                label: 'Option 1',
                value: 'Option 1',
                default: true,
                id: 'c332a3df-90c2-427d-cadc-5e0a9d04ff60',
              },
              {
                label: 'Option 2',
                value: 'Option 2',
                default: false,
                id: '56e25421-57c6-44d1-484c-8f244f94c32f',
              },
              {
                label: 'Option 3',
                value: 'Option 3',
                default: true,
                id: '76181e31-b2ab-4202-b118-6bff764f8faa',
              },
            ],
            showLabel: true,
          },
        },
      },
    },
    {
      id: '311d6405-09ce-45aa-d897-72d3649a079b',
      hidden: false,
      identifier: 'CHECKBOX',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'form_field_c925',
        pii: false,
        required: false,
        inputType: 'BOOLEAN',
        booleanOptions: {
          validation: {
            enum: [],
          },
          componentType: 'CHECKBOX',
          checkboxOptions: {
            label: {
              nodes: [
                {
                  type: 'PARAGRAPH',
                  id: 'h88m557',
                  nodes: [
                    {
                      type: 'TEXT',
                      id: '',
                      nodes: [],
                      textData: {
                        text: 'This is a checkbox.',
                        decorations: [],
                      },
                    },
                  ],
                  paragraphData: {
                    textStyle: {
                      textAlignment: 'AUTO',
                    },
                  },
                },
              ],
              metadata: {
                version: 1,
                createdTimestamp: '2024-08-29T09:38:55.549Z',
                updatedTimestamp: '2024-08-29T09:38:55.549Z',
                id: '2c3e3ada-11a2-49df-a86d-9ad743a5a09e',
              },
            },
          },
        },
      },
    },
    {
      id: '42b0dd10-599d-43e1-f4ec-2f9f1cdbacf0',
      hidden: false,
      identifier: 'DATE_PICKER',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'date_picker_77eb',
        pii: false,
        required: false,
        inputType: 'STRING',
        stringOptions: {
          validation: {
            format: 'DATE',
            enum: [],
          },
          componentType: 'DATE_TIME',
          dateTimeOptions: {
            label: 'Date picker',
            showLabel: true,
            showPlaceholder: true,
            dateTimeInputType: 'DATE_PICKER',
            datePickerOptions: {
              firstDayOfWeek: 'MONDAY',
            },
          },
        },
      },
    },
    {
      id: '2933c8f2-7725-469f-6304-6dd37f7b20e0',
      hidden: false,
      identifier: 'DROPDOWN',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'dropdown_4c4a',
        pii: false,
        required: false,
        inputType: 'STRING',
        stringOptions: {
          validation: {
            format: 'UNDEFINED',
            enum: ['Option 1', 'Option 2'],
          },
          componentType: 'DROPDOWN',
          dropdownOptions: {
            label: 'Dropdown',
            options: [
              {
                label: 'Option 1',
                value: 'Option 1',
                default: false,
                id: 'afa3de59-59c3-4aa1-e58c-e0f7b2cdbe77',
              },
              {
                label: 'Option 2',
                value: 'Option 2',
                default: false,
                id: '345db19c-be28-40b2-631a-060a837bc848',
              },
            ],
            showLabel: true,
          },
        },
      },
    },
    {
      id: 'b9de78fd-35be-4877-66ca-8a1b1d39216b',
      hidden: false,
      identifier: 'HEADER',
      fieldType: 'DISPLAY',
      displayOptions: {
        header: {
          content: {
            nodes: [
              {
                type: 'HEADING',
                id: '4pdl373',
                nodes: [
                  {
                    type: 'TEXT',
                    id: '',
                    nodes: [],
                    textData: {
                      text: 'Second page',
                      decorations: [],
                    },
                  },
                ],
                headingData: {
                  level: 1,
                  textStyle: {
                    textAlignment: 'AUTO',
                  },
                },
              },
            ],
            metadata: {
              version: 1,
              createdTimestamp: '2024-08-29T09:38:55.559Z',
              updatedTimestamp: '2024-08-29T09:38:55.559Z',
              id: '83ace24c-11b5-40da-a14b-cf6ec4fab6d7',
            },
            documentStyle: {},
          },
        },
      },
    },
    {
      id: 'aa337a06-b503-4be1-ad78-d51d200d2460',
      hidden: false,
      identifier: 'FILE_UPLOAD',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'file_upload_c165',
        pii: false,
        required: false,
        inputType: 'WIX_FILE',
        wixFileOptions: {
          componentType: 'FILE_UPLOAD',
          fileUploadOptions: {
            label: 'File upload',
            showLabel: true,
            buttonText: 'Upload File',
            fileLimit: 1,
            uploadFileFormats: ['VIDEO', 'IMAGE'],
          },
        },
      },
    },
    {
      id: '2c2cd5c4-2141-4298-444b-0814e4935609',
      hidden: false,
      identifier: 'RADIO_GROUP',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'single_choice_8edc',
        pii: false,
        required: false,
        inputType: 'STRING',
        stringOptions: {
          validation: {
            format: 'UNDEFINED',
            enum: ['Option 1', 'Option 2', 'Option 3'],
          },
          componentType: 'RADIO_GROUP',
          radioGroupOptions: {
            label: 'Single choice',
            options: [
              {
                label: 'Option 1',
                value: 'Option 1',
                default: true,
                id: '1c349276-3193-4e2d-6a56-716ffb7179c3',
              },
              {
                label: 'Option 2',
                value: 'Option 2',
                default: false,
                id: '4fa1b204-42a2-4bb7-2d4e-5cf46b7220af',
              },
              {
                label: 'Option 3',
                value: 'Option 3',
                default: false,
                id: '2e5de5c6-0b45-436e-05d0-8103e0fc7812',
              },
            ],
            showLabel: true,
          },
        },
      },
    },
    {
      id: 'e5be0180-9138-43aa-e5ee-1b2c56b5fd0c',
      hidden: false,
      identifier: 'DONATION',
      fieldType: 'INPUT',
      inputOptions: {
        target: 'donation_25b1',
        pii: false,
        required: false,
        inputType: 'PAYMENT',
        paymentOptions: {
          componentType: 'DONATION_INPUT',
          validation: {
            products: [
              {
                id: '731f16d1-a31f-4571-dc7b-f6c8065644d3',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '10',
                },
              },
              {
                id: '2a175c75-2553-45ca-3f5c-b34c9110bceb',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '20',
                },
              },
              {
                id: 'd208ee0c-d764-4b54-1326-aa62c6546f16',
                productType: 'DIGITAL',
                priceType: 'FIXED_PRICE',
                quantityLimit: {
                  minimum: 1,
                  maximum: 1,
                },
                fixedPriceOptions: {
                  price: '30',
                },
              },
            ],
          },
          donationInputOptions: {
            label: 'Donation',
            options: [
              {
                value: '731f16d1-a31f-4571-dc7b-f6c8065644d3',
                default: false,
              },
              {
                value: '2a175c75-2553-45ca-3f5c-b34c9110bceb',
                default: false,
              },
              {
                value: 'd208ee0c-d764-4b54-1326-aa62c6546f16',
                default: false,
              },
            ],
            numberOfColumns: 'THREE',
            showLabel: true,
          },
        },
      },
    },
  ],
  steps: [
    {
      id: '51d9bd3a-e0ff-472d-180b-d964ff9a4d14',
      name: 'Page 1',
      hidden: false,
      layout: {
        large: {
          items: [
            {
              fieldId: '6004517a-fece-4087-0b10-ba4548ddd182',
              row: 11,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: 'd14ec67e-dab0-4a89-770a-405934c50534',
              row: 0,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: '6fa99389-a5e1-465f-e235-c3589e657126',
              row: 2,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: 'f6a70a2b-c6b4-4bd9-6980-7b872b426457',
              row: 1,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: '0acb9567-6b51-446b-0466-8e9b155775f6',
              row: 3,
              column: 6,
              width: 6,
              height: 1,
            },
            {
              fieldId: 'a869b366-732b-487f-0a05-32d022a099e0',
              row: 3,
              column: 0,
              width: 6,
              height: 1,
            },
            {
              fieldId: '008f6554-5801-4e17-d97a-1bd57dda89f0',
              row: 8,
              column: 0,
              width: 6,
              height: 1,
            },
            {
              fieldId: 'b6451976-0ff0-40eb-07da-9adfd3ff6e1c',
              row: 9,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: '311d6405-09ce-45aa-d897-72d3649a079b',
              row: 6,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: '42b0dd10-599d-43e1-f4ec-2f9f1cdbacf0',
              row: 4,
              column: 0,
              width: 6,
              height: 1,
            },
            {
              fieldId: '2933c8f2-7725-469f-6304-6dd37f7b20e0',
              row: 4,
              column: 6,
              width: 6,
              height: 1,
            },
            {
              fieldId: 'aa337a06-b503-4be1-ad78-d51d200d2460',
              row: 7,
              column: 0,
              width: 5,
              height: 1,
            },
            {
              fieldId: '2c2cd5c4-2141-4298-444b-0814e4935609',
              row: 10,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: 'e5be0180-9138-43aa-e5ee-1b2c56b5fd0c',
              row: 5,
              column: 0,
              width: 12,
              height: 1,
            },
          ],
          sections: [],
        },
      },
    },
    {
      id: '62d0dec7-75ae-404f-c6c2-7b10799bc313',
      name: 'Page 2',
      hidden: false,
      layout: {
        large: {
          items: [
            {
              fieldId: 'd2f37505-9592-4f5b-3599-7275075e7054',
              row: 1,
              column: 0,
              width: 12,
              height: 1,
            },
            {
              fieldId: 'b9de78fd-35be-4877-66ca-8a1b1d39216b',
              row: 0,
              column: 0,
              width: 12,
              height: 1,
            },
          ],
          sections: [],
        },
      },
    },
  ],
  rules: [],
  revision: '9',
  createdDate: '2024-08-29T08:44:19.339Z',
  updatedDate: '2024-08-29T10:09:50.202Z',
  properties: {
    name: 'My Form',
    disabled: false,
  },
  deletedFields: [],
  deletedFieldsV2: [],
  kind: 'REGULAR',
  postSubmissionTriggers: {
    upsertContact: {
      fieldsMapping: {},
      labels: [],
    },
  },
  extendedFields: {
    namespaces: {
      '@forms/form-app': {
        automationId: 'd93f6af7-85c7-4a6e-b768-c5841663420a',
      },
    },
  },
  namespace: 'wix.form_app.form',
  nestedForms: [],
  mediaFolderId: '5e1664902bad41e5a90f77e39e7a55bd',
  spamFilterProtectionLevel: 'ADVANCED',
  submitSettings: {
    submitSuccessAction: 'THANK_YOU_MESSAGE',
    thankYouMessageOptions: {
      durationInSeconds: 8,
      richContent: {
        nodes: [
          {
            type: 'PARAGRAPH',
            id: '7qk8d113',
            nodes: [
              {
                type: 'TEXT',
                id: '',
                nodes: [],
                textData: {
                  text: 'Thanks, we received your submission.',
                  decorations: [],
                },
              },
            ],
            paragraphData: {
              textStyle: {
                textAlignment: 'CENTER',
              },
            },
          },
        ],
        metadata: {
          version: 1,
          createdTimestamp: '2024-08-29T08:44:11.172Z',
          updatedTimestamp: '2024-08-29T08:44:11.172Z',
          id: '71152471-db25-44d5-ad48-b26d57b60f64',
        },
      },
    },
  },
  fieldGroups: [],
};
